import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { Button, Card, Col, Row } from 'react-bootstrap'

const Post = ({ post }) => {
  const { featuredImage, cover_image, cover_color } = post.frontmatter
  const image = featuredImage
    ? featuredImage.childImageSharp.fluid.src
    : cover_image

  return (
    <Card
      className="my-2"
      style={{
        backgroundColor: cover_color,
        backgroundSize: 'cover',
        backgroundImage: `url("${image}")`,
      }}
    >
      <Card.Img variant="top" src={post.frontmatter.cover_image} />
      <Card.Body>
        <Card.Title className="bg-light p-2">
          <Link to={post.slug}>{post.frontmatter.title}</Link>
        </Card.Title>
        <Card.Text className="bg-light p-2 my-4">{post.excerpt}</Card.Text>
        <Link className="text-white" to={post.slug}>
          <Button variant="primary">Read more</Button>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default ({ data }) => {
  const bannerConfig = {
    imgUrl: data.file.childImageSharp.fixed.src,
    title: "Antonin's Blog",
  }

  return (
    <Layout bannerConfig={bannerConfig}>
      <Row>
        {data.allMdx.edges.map(({ node }) => (
          <Col key={node.id} sm={6} lg={4}>
            <Post key={node.id} post={node} />
          </Col>
        ))}
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "basic-header.jpg" }) {
      childImageSharp {
        fixed(width: 2000) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 12
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            cover_color
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          slug
          excerpt
        }
      }
    }
  }
`
